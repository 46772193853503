import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Player from 'react-player/youtube';
import {
  AspectRatio,
  Box,
  Button,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import Hexagon from '../../components/Shapes/Hexagon';
import { IronhackLogo } from '../../icons/IronhackLogo';
import { updateDataLayer } from '../../lib/datalayer';
import { getLinkByPageName } from '../../lib/links';
import { getGaConnectorValues } from '../../lib/ga-connector';
import { courseToSalesforce } from '../../lib/salesforce-integration';
import useUserLocation from '../../hooks/useUserLocation';

type TActivityMessage = {
  source: string;
  payload: {
    activity: number;
    section: number;
    valid: boolean;
  };
};

const VIDEO_SRC = 'https://www.youtube.com/watch?v=bScE_TB1iXA';
const ACTIVITY_SRC =
  'https://ironhack-edu.github.io/short-courses/final-activity/activity-all.html';

const PLAY_VIDEO_EVENT = {
  event: 'gaEvent',
  eventCategory: 'navigation',
  eventAction: 'click video',
  eventLabel: 'JS-Shortcourse::video::javascript',
};
const FINISHED_SHORTCOURSE_EVENT = {
  event: 'gaEvent',
  eventCategory: 'interest',
  eventAction: 'click cta',
  eventLabel: 'JS-Shortcourse::completed bootcamp',
};

const ShortCourseMain = (props): React.ReactElement => {
  const { t } = useTranslation();
  const { userLocation } = useUserLocation();
  const [sectionValidState, setSectionValidState] = useState<boolean[][]>([
    new Array(4).fill(false),
    new Array(3).fill(false),
    new Array(1).fill(false),
  ]);
  const [activityValidState, setActivityValidState] = useState<boolean[]>(
    new Array(3).fill(false)
  );

  useEffect(() => {
    const messageHandler = (e: { data: TActivityMessage }): void => {
      const { data } = e;
      if (data.source === 'IRON_LESSON_ACTIVITY') {
        const newSectionValidState = _.set(
          data.payload.section - 1,
          data.payload.valid,
          sectionValidState[data.payload.activity - 1]
        );

        setSectionValidState(
          _.set(
            data.payload.activity - 1,
            newSectionValidState,
            sectionValidState
          )
        );

        setActivityValidState(
          _.set(
            data.payload.activity - 1,
            newSectionValidState.every(Boolean),
            activityValidState
          )
        );
      }
    };

    window.addEventListener('message', messageHandler);

    return (): void => window.removeEventListener('message', messageHandler);
  }, [
    activityValidState,
    setActivityValidState,
    sectionValidState,
    setSectionValidState,
  ]);

  const nextPageHandler = (): Promise<any> => {
    updateDataLayer(FINISHED_SHORTCOURSE_EVENT);
    const baseUrl = process.env.IRONHACK_API?.slice(0, -8) as string;
    const gaValues = getGaConnectorValues(document.cookie || '');

    return fetch(
      `${baseUrl}/formHandlerSubmission/shortCourseActivityComplete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          _.merge(
            {
              sy_BootcampType: courseToSalesforce('webft'),
              email: props?.location?.state?.email,
              sy_campus: userLocation.campusVisited,
            },
            gaValues
          )
        ),
      }
    ).catch((error) => {
      // doing nothing with error - just moving forward
      console.error('ERROR:', error);
    });
  };

  return (
    <SimpleGrid columns={2}>
      <GridItem colSpan={[2, null, 1]} minH={[null, null, '100vh']}>
        <Box
          as={Link}
          left={['2.4rem', null, '8rem']}
          pos="absolute"
          to={getLinkByPageName('home')}
          top={['2rem', null, '1.6rem']}
        >
          <IronhackLogo boxSize={['3.2rem', null, '4.8rem']} />
        </Box>
        <Box
          mb={[3]}
          mt={[9, null, 13]}
          mx={[1, null, 7]}
          textAlign={[null, null, 'center']}
        >
          <Text mx={[2]} textStyle="title1">
            {'1. '}
            {t('short-courses:shortCourses.web.js.lesson.section.video.title')}
          </Text>
          <AspectRatio my={[2, null, 5]} ratio={16 / 9}>
            <Player
              controls
              height="100%"
              onStart={(): void => updateDataLayer(PLAY_VIDEO_EVENT)}
              url={VIDEO_SRC}
              width="100%"
            />
          </AspectRatio>
          <Text fontWeight="bold" mb={[2]} mx={[2]} textStyle="body4">
            {t(
              'short-courses:shortCourses.web.js.lesson.section.video.direction'
            )}
          </Text>
          <Text color="darkBlue.64" mx={[2]} textStyle="body5">
            {t(
              'short-courses:shortCourses.web.js.lesson.section.video.summary'
            )}
          </Text>
        </Box>
      </GridItem>
      <GridItem
        bg="darkBlue.100"
        colSpan={[2, null, 1]}
        color="white"
        minH={[null, null, '100vh']}
        overflow="hidden"
        pos="relative"
      >
        <Hexagon
          boxSize={['64rem']}
          left={['4.5rem', null, '25.5rem']}
          opacity="0.07"
          pos="absolute"
          top={['-45rem', null, '-21rem']}
          transform={['rotate(30deg)', null, 'rotate(38deg)']}
        />
        <Hexagon
          boxSize={['68rem', null, '72rem']}
          left={['-21rem', null, '-4rem']}
          opacity="0.07"
          pos="absolute"
          top={['3.6rem', null, '28rem']}
          transform={['rotate(35deg)']}
        />
        <Box
          mb={[8]}
          mt={[4, null, 13]}
          mx={[1, null, 7]}
          textAlign={[null, null, 'center']}
        >
          <Text color="white" mx={[2]} textStyle="title1">
            {'2. '}
            {t(
              'short-courses:shortCourses.web.js.lesson.section.activity.title'
            )}
          </Text>
          <AspectRatio my={[2, null, 5]} ratio={16 / 9}>
            <iframe src={ACTIVITY_SRC} title="JS Short Course Activity" />
          </AspectRatio>
          <Text fontWeight="bold" mb={[2]} mx={[2]} textStyle="body4">
            {t(
              'short-courses:shortCourses.web.js.lesson.section.activity.direction'
            )}
          </Text>
          <Text
            color="whiteAlpha.60"
            mb={[3, null, 8]}
            mx={[2]}
            textStyle="body5"
          >
            {t(
              'short-courses:shortCourses.web.js.lesson.section.activity.summary'
            )}
          </Text>
          <Box textAlign={['inherit', null, 'right']}>
            <Button
              as={activityValidState.every(Boolean) ? Link : Button}
              disabled={!activityValidState.every(Boolean)}
              onClick={nextPageHandler}
              state={{ email: props?.location?.state?.email }}
              to={'../thank-you'}
            >
              {t('short-courses:shortCourses.web.js.lesson.cta.label')}
            </Button>
          </Box>
        </Box>
      </GridItem>
    </SimpleGrid>
  );
};

export const query = graphql`
  query ($locale: String!, $defaultLanguage: String!) {
    locales: allLocale(
      filter: {
        lng: { in: [$locale, $defaultLanguage] }
        ns: { regex: "/(seo|short-courses)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default ShortCourseMain;
